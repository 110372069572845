import { createSlice } from "@reduxjs/toolkit";
import { getServiceDetails, getServices } from "../Services/dashboard.service";

const initialState = {
  services: null,
  serviceDetails: null,
};

const homeSlice = createSlice({
  name: "homeSlice",
  initialState,
  extraReducers: ({ addCase }) => {
    addCase(getServices.fulfilled, (state, { payload }) => {
      if (payload?.success) {
        state.services = payload?.data.data;
      }
    });

    addCase(getServiceDetails.fulfilled, (state, { payload }) => {
      if (payload?.success) {
        state.serviceDetails = payload?.data;
      }
    });
  },
});

export default homeSlice.reducer;
