import React from 'react'
import { Link } from "react-router-dom"
import arrowIcon from "../../../assets/arrow.png";
import buttonLineIcon from "../../../assets/bottom-line.png"

const PrivacyPolicy = () => {
  return (
    <div className="container-fluid">
    <div className="row row-100vh">
        <div className="col-md-12 top mt-5 px-md-5">
            <Link to={"/setting"}><img src={arrowIcon} alt="image" className="img-fluid" /></Link>
            <h4 className="my-3 text-color">Privacy Policy</h4>
        </div>
        <div className="col-md-12 middle mt-4 px-md-5">
            <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to
                make a type specimen book.</p>
            <p className="">On the other hand, we denounce with righteousindignation and dislike men who
                are so beguiled and demoralized
                by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and
                trouble that
                are bound to ensue; and equal blame belongs to those who faiin their duty through weakness of will,
                which is the
                same as saying through shrinking from toil and pain.</p>
            <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to
                make a type specimen book.</p>
            <p className="">On the other hand, we denounce with righteousindignation and dislike men who
                are so beguiled and demoralized
                by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and
                trouble that
                are bound to ensue; and equal blame belongs to those who faiin their duty through weakness of will,
                which is the
                same as saying through shrinking from toil and pain.</p>
            <ul className="">
                <li>Lorem ipsum dolor sit amet, consectetur </li>
                <li>dipiscing elit.</li>
                <li>Donec a elit quis dolor euismod dignissim.</li>
                <li>Donec a elit quis dolor euismod dignissim.</li>
                <li>Donec a elit quis dolor euismod dignissim.</li>
                <li>Donec a elit quis dolor euismod dignissim.</li>
            </ul>
        </div>
        <div className="col-md-12 bottom">
            <div className="text-center">
                <img src={buttonLineIcon} alt="line" className=" bottom-line img-fluid" />
            </div>
        </div>
    </div>
</div>
  )
}

export default PrivacyPolicy