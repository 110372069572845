import React from 'react'
import pageNotrFoundIcon from "../assets/pageNotFound.png"
import buttonLineIcon from "../assets/bottom-line.png"

const PageNoteFound = () => {
  return (
    <div> <div className="container-fluid">
    <div className="row row-100vh">

            <div className="col-md-12 middle mt-4 px-md-5">
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <img src={pageNotrFoundIcon} alt="logo" className="img-fluid" />
                </div>
                <h4 className="fw-semibold text-color text-center mt-3">Page Not Found</h4>
            </div>
        

        <div className="col-md-12 bottom">
            <div className="text-center">
                <img src={buttonLineIcon} alt="line" className=" bottom-line img-fluid" />
            </div>
        </div>

    </div>
</div></div>
  )
}

export default PageNoteFound