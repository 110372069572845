import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import Register from "./components/register/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import UpcomingServices from "./pages/dashboard/UpcomingServices";
import Details from "./pages/dashboard/Details";
import Schedule from "./pages/schedule/Schedule";
import ScheduleDetail from "./pages/schedule/Detail";
import ScheduleCompleteDetail from "./pages/schedule/CompleteDetail";
import Invoice from "./pages/invoice/Invoice";
import InvoiceDetail from "./pages/invoice/InvoiceDetails";
import Services from "./pages/services/Services";
import Reporting from "./pages/reporting/Reporting";
import ReportDetail from "./pages/reporting/ReportDetails";
import Setting from "./pages/setting/Setting";
import EditProfile from "./pages/setting/editProfile/EditProfile";
import Notifications from "./pages/setting/notifications/Notification";
import AboutUs from "./pages/setting/aboutUs/AboutUs";
import PrivacyPolicy from "./pages/setting/privacyPolicy/PrivacyPolicy";
import HelpAndSupport from "./pages/setting/helpAndSupport/HelpAndSupport";
import Splash from "./components/Splash";
import App from "./App";
import PageNoteFound from "./pageNotFound/PageNoteFound";
const Login = React.lazy(() => import("./components/login/Login"));

const Routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<Splash />}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: "login",
        element: (
          <Suspense fallback={<Splash />}>
            <Login />
          </Suspense>
        ),
      },
      // {
      //   path: "register",
      //   element: <Register />,
      // },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "dashboard-upcoming-service",
        element: <UpcomingServices />,
      },
      {
        path: "dashboard-detail/:id",
        element: <Details />,
      },
      {
        path: "schedule",
        element: <Schedule />,
      },
      {
        path: "schedule-detail/:id",
        element: <ScheduleDetail />,
      },
      {
        path: "schedule-complete-detail/:id",
        element: <ScheduleCompleteDetail />,
      },
      {
        path: "invoice",
        element: <Invoice />,
      },
      {
        path: "invoice-detail/:id",
        element: <InvoiceDetail />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "reporting",
        element: <Reporting />,
      },
      {
        path: "report-detail/:id",
        element: <ReportDetail />,
      },
      {
        path: "setting",
        element: <Setting />,
      },
      {
        path: "edit-profile",
        element: <EditProfile />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "about-us",
        element: <AboutUs />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "help-and-support",
        element: <HelpAndSupport />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNoteFound />,
  },
]);

export default Routes;
