import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    LoginPost: builder.mutation({
      query: (data) => ({
        url: "/client/login",
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    EditProfile: builder.mutation({
      query: (data) => ({
        url: "/client",
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("logintoken")}`,
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const { useLoginPostMutation, useEditProfileMutation } = loginApi;
