import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import filterIcon from "../../assets/filter.svg";
import buttomLine from "../../assets/bottom-line.png";
import invoiceIcon from "../../assets/invoice-icon.png";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../Services/invoice.service";
import moment from "moment";

const Invoice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const { invoices } = useSelector((state) => state.invoice);
  const invoiceDetailNavigate = (id) => {
    navigate(`/invoice-detail/${id}`);
  };
  // const [selectedOption, setSelectedOption] = useState('');

  // Step 2: Define onChange event handler
  const handleSelectChange = (event) => {
    // Step 3: Update state variable with selected value
    setSelectedDateRange(event.target.value);
  };

  useEffect(() => {
    dispatch(getInvoices({ page: 1, limit: 100 }));
  }, []);

  const invoiceData = [
    {
      id: 1,
      invoiceNumber: "#67433543545",
      customerName: "John Wick",
      amount: "$342.00",
      date: "13 Jan 2024",
    },
    {
      id: 2,
      invoiceNumber: "#67433543545",
      customerName: "John Wick",
      amount: "$342.00",
      date: "13 Jan 2024",
    },
    {
      id: 3,
      invoiceNumber: "#67433543545",
      customerName: "John Wick",
      amount: "$342.00",
      date: "13 Jan 2024",
    },
    {
      id: 4,
      invoiceNumber: "#67433543545",
      customerName: "John Wick",
      amount: "$342.00",
      date: "13 Mar 2024",
    },

    // Add more objects for additional cards if needed
  ];

  // Function to handle filtering based on selected date range
  const handleFilter = () => {
    if (!selectedDateRange) {
      return invoiceData; // If no filter is selected, return all data
    }

    // Filter based on selected date range
    const filteredData = invoiceData.filter((invoice) => {
      const invoiceDate = new Date(invoice.date);
      const currentDate = new Date();
      switch (selectedDateRange) {
        case "last30Days":
          return (
            invoiceDate >= new Date(currentDate - 30 * 24 * 60 * 60 * 1000)
          );
        case "last60Days":
          return (
            invoiceDate >= new Date(currentDate - 60 * 24 * 60 * 60 * 1000)
          );
        case "last90Days":
          return (
            invoiceDate >= new Date(currentDate - 90 * 24 * 60 * 60 * 1000)
          );
        case "ninetyPlusDays":
          return invoiceDate < new Date(currentDate - 90 * 24 * 60 * 60 * 1000);
        default:
          return true;
      }
    });

    return filteredData;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Layout />
        <div className="col-md-12 top my-4 px-md-5">
          <div className="clearfix">
            <div className="float-start">
              <h4>Filter</h4>
            </div>
            <div className="float-end">
              <button
                className="btn btn-primary bg-none p-0 border-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
              >
                <img src={filterIcon} alt="filter" className="img-fluid" />
              </button>

              <div
                className="offcanvas offcanvas-bottom pt-3"
                tabindex="-1"
                id="offcanvasBottom"
                aria-labelledby="offcanvasBottomLabel"
              >
                <div className="offcanvas-header">
                  <h5
                    className="offcanvas-title text-color ms-auto fw-bolder"
                    id="offcanvasBottomLabel"
                  >
                    Filter
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body overflow-hidden">
                  <form>
                    <div className="mb-3">
                      <label
                        for="exampleInputEmail1"
                        className="form-label fw-semibold text-black"
                      >
                        Date
                      </label>
                      <select
                        className="form-select py-3 rounded-pill"
                        aria-label="Default select example"
                        value={selectedDateRange}
                        onChange={handleSelectChange}
                      >
                        <option disabled selected>
                          Select Date
                        </option>
                        <option value="1">Current</option>
                        <option value="last30Days">Last 30 Days</option>
                        <option value="last60Days">Last 60 Days</option>
                        <option value="last90Days">Last 90 Days</option>
                        <option value="ninetyPlusDays">90 Days +</option>
                      </select>
                    </div>

                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn bg-button btn-lg py-3 me-5 rounded-pill w-100 fw-bolder text-white"
                        // onClick={handleFilter}
                      >
                        Apply
                      </button>

                      <button
                        type="button"
                        className="btn bg-button-reset btn-lg py-3  rounded-pill w-100 fw-bolder"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                </div>

                {/* <div className="col-md-12 bottom">
                  <div className="text-center">
                    <img
                      src={buttomLine}
                      alt="line"
                      className=" bottom-line img-fluid"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 px-104">
          {invoices?.map((invoice) => (
            <div
              key={invoice._id}
              className="card mb-3 card-shadow"
              onClick={() => invoiceDetailNavigate(invoice?._id)}
            >
              <div className="card-body position-relative">
                <img
                  src={invoiceIcon}
                  className="img-fluid float-end position-absolute top-0 end-0  me-4 mt-3"
                  alt="invoices-icon"
                />
                <p className="card-text text-grey mb-0">
                  #{invoice?.invoice_id}
                </p>
                <h6 className="text-black my-2">{invoice?.invoiceTitle}</h6>
                <div className="clearfix">
                  <div className="float-start">
                    <h6 className="mb-0  text-color fw-bolder">
                      {invoice?.total}
                    </h6>
                  </div>
                  <div className="float-end">
                    <p className="mb-0">
                      <small>
                        {moment(invoice?.createdAt).format("MMM DD YYYY")}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* {handleFilter().map((invoice) => (
            <div
              key={invoice.id}
              className="card mb-3 card-shadow"
              onClick={() => invoiceDetailNavigate(invoice.id)}
            >
              <div className="card-body position-relative">
                <img
                  src={invoiceIcon}
                  className="img-fluid float-end position-absolute top-0 end-0 me-md-5 me-4"
                  alt="invoices-icon"
                />
                <p className="card-text text-grey mb-0">
                  {invoice.invoiceNumber}
                </p>
                <h6 className="text-black my-2">{invoice.customerName}</h6>
                <div className="clearfix">
                  <div className="float-start">
                    <h6 className="mb-0  text-color fw-bolder">
                      {invoice.amount}
                    </h6>
                  </div>
                  <div className="float-end">
                    <p className="mb-0">
                      <small>{invoice.date}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>
        {/* <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttomLine}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Invoice;
