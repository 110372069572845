import React from "react";
import arrowIcon from "../../assets/arrow.png";
import { Link } from "react-router-dom";
import logoThumbnail from "../../assets/logo-thumbnail.svg";
import buttomLine from "../../assets/bottom-line.png";
import invoiceIcon from "../../assets/invoice-icon.png";
import location from "../../assets/location.svg";

const CompleteDetail = () => {
  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-12 top mt-5 px-md-5">
          <Link to={"/schedule"}>
            <img src={arrowIcon} alt="image" className="img-fluid" />
          </Link>
          <h4 className="my-3 text-color">Details</h4>
        </div>
        <div className="col-md-12 px-md-5">
          <div className="card mb-3 border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-1 col-2">
                  <img src={logoThumbnail} className="img-fluid " alt="image" />
                </div>
                <div className="col-md-8 col-10 ps-0">
                  <h5 className="card-title text-color mb-1">
                    Rodent Pest Control Service
                  </h5>
                  <p className="card-text">
                    Pest Control & Hygiene Services, Seychelles
                  </p>
                </div>
                <div className="col-md-3">
                  <div className="float-end">
                    <p className="mb-0">
                      <small>3 days ago</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mt-3 text-color">Main Duties</h5>
          <ul className="ps-4">
            <li>
              Inspect buildings and premises for signs of pests infestation
            </li>
            <li>
              Apply pesticides in and around buildings and other structures
            </li>
            <li>Operate equipment in a safe and responsible manner</li>
          </ul>
          <hr />
          <h5 className="text-color">Invoice</h5>
          <div className="card mb-3 card-shadow">
            <div className="card-body position-relative">
              <img
                src={invoiceIcon}
                className="img-fluid float-end position-absolute top-0 end-0 me-md-5 me-4"
                alt="invoices-icon"
              />
              <p className="card-text text-grey  mb-0">#67433543545</p>
              <h6 className="text-black my-2">John Wick</h6>
              <div className="clearfix">
                <div className="float-start">
                  <h6 className="mb-0  text-color fw-bolder">$342.00</h6>
                </div>
                <div className="float-end">
                  <p className="mb-0">
                    <small>13 Jan 2024</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h4 className="mt-4 text-color">Location</h4>
          <h6 className="mb-0">
            <img src={location} className="me-1 img-fluid" alt="img-fluid" />
            Mahé Island
          </h6>
          <h5 className="mt-4 text-color">Service Type</h5>
          <h6 className="mb-0 ">Pest Control</h6>
          <h5 className="mt-4 text-color">Timings</h5>
          <h6 className="mb-0 ">03 Jan 2023, 11:20AM</h6>
          <h5 className="mt-4 text-color">Signature</h5>
          <h6 className="mb-0 ">
            <i>dhrte</i>
          </h6>
          <div className="clearfix">
            <div className="float-start">
              <h5 className="mt-3 text-color">Status</h5>
            </div>
            <div className="float-end">
              <p className="mb-0 bg-green-completed px-4 py-2 text-white rounded-pill fw-bold">
                <small>Paid</small>
              </p>
            </div>
          </div>
          <button className="btn bg-button w-100 btn-lg py-3  mt-4 text-white rounded-pill fw-bolder">
            Completed
          </button>
        </div>
        <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttomLine}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteDetail;
