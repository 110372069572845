import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrowIcon from "../../assets/arrow.png";
import logoThumbnail from "../../assets/logo-thumbnail.svg";
import location from "../../assets/location.svg";
import buttomLine from "../../assets/bottom-line.png";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../Services/dashboard.service";
import moment from "moment";

function UpcomingServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { services } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getServices({ page: 1, limit: 100, slug: "upcoming" }));
  }, []);

  console.log(services);

  const cardData = [
    {
      id: 1,
      title: "Rodent Pest Control Service",
      description: "Pest Control & Hygiene Services, Seychelles",
      location: "Mahé Island",
      date: "3 days ago",
    },
    {
      id: 2,
      title: "Rodent Pest Control Service",
      description: "Pest Control & Hygiene Services, Seychelles",
      location: "Mahé Island",
      date: "3 days ago",
    },
    {
      id: 3,
      title: "Rodent Pest Control Service",
      description: "Pest Control & Hygiene Services, Seychelles",
      location: "Mahé Island",
      date: "3 days ago",
    },
    // Add more objects for additional cards if needed
  ];

  const navigateDetail = (id) => {
    navigate(`/dashboard-detail/${id}`);
  };

  return (
    <div className="container-fluid">
      <div className="row row-100vh">
        <div className="col-md-12 top mt-5 px-104">
          <Link to={"/dashboard"}>
            <img src={arrowIcon} alt="image" className="img-fluid" />
          </Link>
          <h4 className="my-3 text-color">Upcoming Services</h4>
        </div>
        <div className="col-md-12 middle px-104">
          {services?.map((service, i) => (
            <div
              key={i}
              className="card mb-3 card-shadow"
              onClick={(e) => navigateDetail(service?._id)}
            >
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-md-1 col-2">
                    <img
                      src={logoThumbnail}
                      className="img-fluid w-100"
                      alt="image"
                    />
                  </div>
                  <div className="col-md-11 col-10">
                    <h5 className="card-title text-color mb-1">
                      {service?.title}
                    </h5>
                    <p className="card-text">{service?.description}</p>
                  </div>
                </div>
                <p className="card-text">
                  <small className="text-muted">
                    Inspect buildings and premises for signs of pests or
                    infestation.
                  </small>
                </p>
                <div className="clearfix">
                  <div className="float-start">
                    <h6 className="mb-0 text-color">
                      <img
                        src={location}
                        className="me-1 img-fluid"
                        alt="img-fluid"
                      />
                      {"Mahé Island"}
                    </h6>
                  </div>
                  <div className="float-end">
                    <p className="mb-0">
                      <small>
                        {moment(service?.start_date).format("MMM DD YYYY")}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttomLine}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpcomingServices;
