import React from 'react'
import Layout from '../layout/Layout'

const Services = () => {
  return (
    <div class="container-fluid">
    <div class="row row-100vh">
        <Layout />
        <div>
            This is my Services
        </div>
    </div>
</div>
  )
}

export default Services