import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import images from "../../assets/banner.png";
import logoThumbnail from "../../assets/logo-thumbnail.svg";
import location from "../../assets/location.svg";
import buttomLine from "../../assets/bottom-line.png";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../Services/dashboard.service";
import moment from "moment";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getServices({ page: 1, limit: 3, slug: "upcoming" }));
  }, []);

  const navigateDetail = (id) => {
    navigate(`/dashboard-detail/${id}`);
  };

  return (
    <div className="container-fluid">
      {/* <div className="row row-100vh "> */}
      <div className="row">
        <Layout />

        <div className="col-md-12 my-md-4 px-104">
          <img src={images} alt="banner" className="img-fluid w-100 banner" />
        </div>
        <div className="col-md-12 top px-104">
          <div className="clearfix">
            <div className="float-start">
              <h4 className="my-3 text-color">Upcoming Services</h4>
            </div>
            <div className="float-end mt-3">
              <Link to={"/dashboard-upcoming-service"} className="text-black">
                View all
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 middle px-md-5"> */}
        <div className="col-md-12 px-104">
          {services?.map((service, i) => (
            <div
              key={service._id}
              className="card mb-3 card-shadow"
              onClick={(e) => navigateDetail(service?._id)}
            >
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-md-1 col-2">
                    <img
                      src={logoThumbnail}
                      className="img-fluid w-100"
                      alt="image"
                    />
                  </div>
                  <div className="col-md-11 col-10">
                    <h5 className="card-title text-color mb-1">
                      {service?.title}
                    </h5>
                    <p className="card-text">{service?.description}</p>
                  </div>
                </div>
                <p className="card-text">
                  <small className="text-muted">
                    Inspect buildings and premises for signs of pests or
                    infestation.
                  </small>
                </p>
                <div className="clearfix">
                  <div className="float-start">
                    <h6 className="mb-0 text-color">
                      <img
                        src={location}
                        className="me-1 img-fluid"
                        alt="img-fluid"
                      />
                      {"Mahé Island"}
                    </h6>
                  </div>
                  <div className="float-end">
                    <p className="mb-0">
                      <small>
                        {moment(service?.start_date).format("MMM DD YYYY")}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttomLine}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
