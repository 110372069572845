import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrowIcon from "../../../assets/arrow.png";
import userIcon from "../../../assets/user.svg";
import emailIcon from "../../../assets/email.svg";
import passwordIcon from "../../../assets/password.svg";
import buttonLineIcon from "../../../assets/bottom-line.png";
import locationIcon from "../../../assets/location.svg";
import { useEditProfileMutation } from "../../../Services/LoginApi";
import { toast } from "react-toastify";
import { Field, FieldArray, Form, Formik, useFormik } from "formik";

const EditProfile = () => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  const [editUserProfile] = useEditProfileMutation();

  //   const updateHandler = (e) => {
  //     e.preventDefault();

  //     editUserProfile(data)
  //       .unwrap()
  //       .then((res) => {
  //         toast.success(res?.message);
  //         localStorage.setItem("name", customerName);
  //         localStorage.setItem("email", email);
  //         if (res?.success) {
  //           navigate("/setting");
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error(err?.data?.message);
  //       });
  //   };

  return (
    <div className="container-fluid">
      <div className="row row-100vh">
        <div className="col-md-12 top mt-5 px-md-5">
          <Link to={"/setting"}>
            <img src={arrowIcon} alt="image" className="img-fluid" />
          </Link>
          <h4 className="my-3 text-color">Edit Profile</h4>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            full_name: userData.full_name,
            email: userData?.email,
            password: userData?.password,
            phone_number: userData?.phone_number,
            address: userData?.address,
            zones: userData?.zones,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            editUserProfile(values)
              .unwrap()
              .then((res) => {
                toast.success(res?.message);
                setUserData(res?.data);
                localStorage.setItem("userData", JSON.stringify(res?.data));
              })
              .catch((err) => {
                toast.error(err?.data?.message);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <div className="col-md-12 middle mt-4 px-md-5">
              <Form>
                <div className="position-relative">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-color h6 fw-semibold"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-pill py-3 mb-4"
                    placeholder="Enter your Name"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name="full_name"
                    value={values?.full_name}
                    onChange={handleChange}
                  />
                  <img
                    src={userIcon}
                    alt="user"
                    className="img-fluid position-absolute password"
                  />
                </div>

                <div className="position-relative">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-color h6 fw-semibold"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control rounded-pill py-3 mb-4"
                    placeholder="Enter your Email Address"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <img
                    src={emailIcon}
                    alt="user"
                    className="img-fluid position-absolute password"
                  />
                </div>

                <div className="position-relative">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-color h6 fw-semibold"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control rounded-pill py-3 mb-4 position-relative"
                    placeholder="Enter your Password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name="password"
                    onChange={handleChange}
                  />
                  <img
                    src={passwordIcon}
                    alt="password"
                    className="img-fluid position-absolute password"
                  />
                </div>
                <div className="position-relative">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-color h6 fw-semibold"
                  >
                    Phone Number
                  </label>
                  <input
                    type="number"
                    className="form-control rounded-pill py-3 mb-4 position-relative"
                    placeholder="Enter your Phone Number"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={values?.phone_number}
                    name="phone_number"
                    onChange={handleChange}
                  />
                  <img
                    src={userIcon}
                    alt="password"
                    className="img-fluid position-absolute password bw-filter"
                  />
                </div>
                <div className="position-relative">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-color h6 fw-semibold"
                  >
                    Address
                  </label>
                  <input
                    className="form-control rounded-pill py-3 mb-4 position-relative"
                    placeholder="Enter your Address"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={values?.address}
                    name="address"
                    onChange={handleChange}
                  />
                  <img
                    src={locationIcon}
                    alt="password"
                    className="img-fluid position-absolute password bw-filter"
                  />
                </div>
                <FieldArray
                  name="zones"
                  render={(arrayHelpers) => (
                    <div>
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-color h6 fw-semibold"
                      >
                        Zones
                      </label>
                      {values.zones && values.zones.length > 0 ? (
                        values.zones.map((friend, index) => (
                          <div key={index}>
                            <div className="row">
                              <div className="col-md-11">
                                <Field
                                  className="form-control rounded-pill py-3 mb-2 position-relative"
                                  name={`zones.${index}`}
                                />
                              </div>
                              <div className="col-md-1">
                                <button
                                  className="zones-btn mx-1"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                  -
                                </button>
                                <button
                                  className="zones-btn mx-1"
                                  type="button"
                                  onClick={() => arrayHelpers.push("")} // insert an empty string at a position
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <button
                          className="zones-btn add-zone-btn"
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                        >
                          {/* show this when user has removed all friends from the list */}
                          Add a Zone
                        </button>
                      )}
                    </div>
                  )}
                />

                <button
                  type="submit"
                  className="btn btn-lg bg-button w-100 rounded-pill text-white fw-bolder py-3 mt-4"
                >
                  Update
                </button>
              </Form>
            </div>
          )}
        </Formik>

        <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttonLineIcon}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
