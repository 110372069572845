import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import Splash from './components/Splash';

import './App.css';


function App() {

  const [splash, setSplash] = useState(true);

  useEffect(() => {
    setTimeout(() => setSplash(false), 1000)
  }, []);

  return splash ? <Splash /> : <Outlet />;
}

export default App;
