import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import userIcon from "../../assets/user.svg";
import passwordIcon from "../../assets/password.svg";
import emailIcon from "../../assets/email.png";
import bottomLineImage from "../../assets/bottom-line.png";
import { useDispatch } from "react-redux";
import {
  setEmail,
  setPassword,
  setCustomerName,
} from "../../Slices/signUpSlice";
import { useRegisterPostMutation } from "../../Services/SignUpApi";
import FormValidation from "./FormValidation"; // Import the FormValidation component
import { toast } from "react-toastify";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addFormData] = useRegisterPostMutation();
  const [inputField, setInputField] = useState({
    customerName: "",
    email: "",
    password: "",
  });
  const [errField, setErrField] = useState({
    customerNumberErr: "",
    emailErr: "",
    passwordErr: "",
  });

  const submitButton = (e) => {
    e.preventDefault();
    // if (FormValidation({ inputField, setErrField })) {
    //   addFormData(inputField)
    //     .unwrap()
    //     .then((res) => {
    //       toast.success(res?.message);
    //       if (res?.success) {
    //         setInputField({
    //           customerName: "",
    //           email: "",
    //           password: "",
    //         });
    //         setTimeout(() => {
    //           navigate("/login");
    //         }, 2000);
    //       }
    //     })
    //     .catch((err) => {
    //       toast.error(err?.data?.message);
    //     });
    // }
  };
  const inputChangeHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    if (e.target.name === "customerName" && e?.target?.value?.length > 3) {
      dispatch(setCustomerName(e.target.value));
      setErrField((prevState) => ({
        ...prevState,
        customerNumberErr: "",
      }));
    }
    if (e.target.name === "email" && e?.target?.value?.length > 3) {
      dispatch(setEmail(e.target.value));

      setErrField((prevState) => ({
        ...prevState,
        emailErr: "",
      }));
    }
    if (e.target.name === "password" && e?.target?.value?.length > 3) {
      dispatch(setPassword(e.target.value));
      setErrField((prevState) => ({
        ...prevState,
        passwordErr: "",
      }));
    }
  };

  return (
    <>
      <div className="main">
        <div className="row-100vh">
          <div className="bg-green py-5 mb-5 top">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="text-center text-white">
                    <h1>Create your Account</h1>
                    <h4 className="fw-normal mt-3">
                      Please Enter your details
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container middle">
            <div className="row mx-0">
              <div className="col-md-12">
                <form action="">
                  <div className="position-relative">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-color h5 fw-semibold"
                    >
                      Customer Name
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-pill py-3 mb-3"
                      placeholder="Enter your Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="customerName"
                      value={inputField.customerName}
                      onChange={inputChangeHandler}
                    />
                    <img
                      src={userIcon}
                      alt="user"
                      className="img-fluid position-absolute password"
                    />
                    {errField?.customerNumberErr?.length > 0 && (
                      <span className="error">
                        {errField?.customerNumberErr}
                      </span>
                    )}
                  </div>

                  <div className="position-relative">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-color h5 fw-semibold"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-pill py-3 mb-3"
                      placeholder="Enter your Email Address"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="email"
                      value={inputField.email}
                      onChange={inputChangeHandler}
                    />
                    <img
                      src={emailIcon}
                      alt="user"
                      className="img-fluid position-absolute password"
                    />
                    {errField?.emailErr?.length > 0 && (
                      <span className="error">{errField?.emailErr}</span>
                    )}
                  </div>

                  <div className="position-relative">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-color h5 fw-semibold"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control rounded-pill py-3 mb-3 position-relative"
                      placeholder="Enter your Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="password"
                      value={inputField.password}
                      onChange={inputChangeHandler}
                    />
                    <img
                      src={passwordIcon}
                      alt="password"
                      className="img-fluid position-absolute password"
                    />
                    {errField?.passwordErr?.length > 0 && (
                      <span className="error">{errField?.passwordErr}</span>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-lg bg-button w-100 rounded-pill text-white fw-bolder py-3 mt-4"
                    onClick={submitButton}
                  >
                    Register
                  </button>
                </form>
                <h5 className="text-center mt-4">
                  <span className="text-muted fw-semibold">
                    Already have an Account?
                  </span>{" "}
                  <Link
                    to={"/login"}
                    className="text-decoration-none text-color fw-bolder"
                  >
                    Login
                  </Link>
                </h5>
              </div>
            </div>
          </div>

          <div className="container bottom">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <img
                    src={bottomLineImage}
                    alt="line"
                    className=" bottom-line img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
