import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import arrowIcon from "../../assets/arrow.png";
import buttonLineIcon from "../../assets/bottom-line.png";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadInvoice,
  getInvoiceDetails,
} from "../../Services/invoice.service";
import moment from "moment";
import api from "../../Services/api";
import InvoiceSubDetails from "./InvoiceSubDetails";

const InvoiceDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { invoiceDetails } = useSelector((state) => state.invoice);

  useEffect(() => {
    if (id) dispatch(getInvoiceDetails(id));
  }, [id]);

  console.log(invoiceDetails);

  const handleDownloadInvoice = () => {
    api
      .get(`/invoice/download/${id}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `invoice${invoiceDetails?.invoice_id}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  console.log(invoiceDetails);

  return (
    <div className="container-fluid">
      <div className="row row-100vh">
        <div className="row">
          <div className="col-md-6 top mt-5 px-104">
            <Link to={"/invoice"}>
              <img src={arrowIcon} alt="image" className="img-fluid" />
            </Link>
            <h4 className="my-3 text-color">Invoice Details</h4>
          </div>
          <div className="col-md-6 top mt-5 px-104 justify-content-end">
            <button
              onClick={handleDownloadInvoice}
              className="btn dowload-icon add-button w-150 float-end mt-4"
            >
              <img
                className="mr-8"
                src={require("../../assets/download.svg").default}
              />
              Download
            </button>
          </div>
        </div>

        <div className="col-md-12 middle px-104">
          <hr />
          <div className="card mb-3 border-0">
            <div className="card-body px-0">
              <h5 className="text-black fw-semibold mb-3">Service Details</h5>
              <div className="row">
                <div className="col-md-9">
                  <div className="mb-3">
                    <p className="text-muted mb-0">Invoice ID</p>
                    <h6 className="text-color fw-semibold">
                      #{invoiceDetails?.invoice_id}
                    </h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3 float-end">
                    <p className="text-muted mb-0">Invoice Date</p>
                    <h6 className="text-color fw-semibold">
                      {moment(invoiceDetails?.invoice_date).format(
                        "MMM DD YYYY"
                      )}
                    </h6>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="mb-3">
                    <p className="text-muted mb-0">Company Name</p>
                    <h6 className="text-color fw-semibold">Pesta</h6>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3 float-end">
                    <p className="text-muted mb-0">Service Type</p>
                    <h6 className="text-color fw-semibold">{"Pest Control"}</h6>
                  </div>
                </div>
              </div>
              <div className="report-subdetails">
                {invoiceDetails?.job_ids?.map((job) => (
                  <InvoiceSubDetails details={job} />
                ))}
              </div>
              {/* <h5 className="text-color ">Balt</h5>
              <h5 className="text-color  mb-4">Insect Control</h5> */}
              <hr />
              <h5 className="text-black fw-semibold mb-3 mt-4">
                Service Summary
              </h5>
              <ul className="list-group">
                <li className="list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center">
                  Subtototal
                  <span className=" text-color">
                    {invoiceDetails?.subtotal?.toFixed(2)}
                  </span>
                </li>
                <li className="list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center">
                  Total Discounts
                  <span className=" text-color">
                    -{invoiceDetails?.discount?.toFixed(1)}%
                  </span>
                </li>
                <li className="list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center">
                  Tax
                  <span className="text-color">
                    {invoiceDetails?.tax?.toFixed(1)}%
                  </span>
                </li>
                <li className="list-group-item border-0 px-0 h6 text-muted d-flex justify-content-between align-items-center">
                  Total
                  <span className="text-color">
                    {invoiceDetails?.total?.toFixed(2)}
                  </span>
                </li>
              </ul>
              <div className="clearfix mt-4">
                <div className="float-start">
                  <h5 className="mb-0 text-black">Status</h5>
                </div>
                <div className="float-end">
                  <h5 className="mb-0 text-color fw-bold">
                    <small>
                      {invoiceDetails?.payment_status?.charAt(0).toUpperCase() +
                        invoiceDetails?.payment_status?.slice(1)}
                    </small>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttonLineIcon}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default InvoiceDetails;
