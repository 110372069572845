import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  password: "",
  customerName: "",
 
};

export const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    // addEmail: (state, action) => {
    //   state.initialState.email= action.payload;
    // },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setCustomerName(state, action) {
      state.customerName = action.payload;
    },
   
  },
});

// Action creators are generated for each case reducer function
export const {
  setEmail,
  setCustomerName,
  setPassword,
} = signUpSlice.actions;

export default signUpSlice.reducer;