import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import arrowIcon from "../../assets/arrow.png";
import logoThumbnail from "../../assets/logo-thumbnail.svg";
import location from "../../assets/location.svg";
import buttomLine from "../../assets/bottom-line.png";
import { useDispatch, useSelector } from "react-redux";
import { getServiceDetails } from "../../Services/dashboard.service";
import moment from "moment";
import BackButton from "../../components/buttons/BackButton";

const Details = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { serviceDetails } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getServiceDetails(id));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row row-100vh">
        <div className="col-md-12 top mt-5 px-104">
          {/* <Link to={"/dashboard"}>
            <img src={arrowIcon} alt="image" className="img-fluid" />
          </Link> */}
          <BackButton />
          <h4 className="my-3 text-color">Details</h4>
        </div>
        <div className="col-md-12 middle px-104">
          <div className="card mb-3 border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-1 col-2">
                  <img
                    src={logoThumbnail}
                    className="img-fluid w-100"
                    alt="image"
                  />
                </div>
                <div className="col-md-8 col-10">
                  <h5 className="card-title text-color">
                    {serviceDetails?.title}
                  </h5>
                  <p className="card-text">
                    Pest Control & Hygiene Services, Seychelles
                  </p>
                </div>
                <div className="col-md-3">
                  <div className="float-end">
                    <p className="mb-0">
                      <small>
                        {moment(serviceDetails?.start_date).format(
                          "MMM DD YYYY"
                        )}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mt-3 text-color">Main Duties</h5>
          <p>{serviceDetails?.duties}</p>
          {/* <ul className="ps-4">
            <li>
              Inspect buildings and premises for signs of pests infestation
            </li>
            <li>
              Apply pesticides in and around buildings and other structures
            </li>
            <li>Operate equipment in a safe and responsible manner</li>
          </ul> */}
          <h5 className="mt-3 text-color">Task description</h5>
          <p>{serviceDetails?.description}</p>

          <h5 className="mt-4 text-color">Location</h5>
          <h6 className="mb-0 ">
            <img src={location} className="me-1 img-fluid" alt="img-fluid" />
            Mahé Island
          </h6>
          <h5 className="mt-4 text-color">Service Type</h5>
          <h6 className="mb-0 ">Pest Control</h6>
          <h5 className="mt-4 text-color">Timings</h5>
          <h6 className="mb-0 ">
            {moment(serviceDetails?.start_time).format("hh:mm A")}
          </h6>
        </div>
        <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttomLine}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
