import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const helpAndSupportApi = createApi({
  reducerPath: "helpAndSupportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    HelpAndSupport: builder.mutation({
      query: (data) => ({
        url: `help-and-support/create-help-and-support`,
        method: "POST",
        body: data,
        headers: {
         authorization: `Bearer ${localStorage.getItem("logintoken")}`,
          "Accept": "application/json"
        }
      }),
    }),


  }),
});

export const {
  useHelpAndSupportMutation
} = helpAndSupportApi;
