import React from 'react';
import { Link,useNavigate } from "react-router-dom";
import arrowIcon from "../../assets/arrow.png";
import rightArrowIcon from "../../assets/right-arrow.png";
import editProfile from "../../assets/edit.svg"
import notificationIcon from "../../assets/notification.svg";
import aboutIcon from "../../assets/about.svg";
import privacyICon from "../../assets/privacy.svg";
import helpIcon from "../../assets/help.svg";
import logoutIcon from "../../assets/logout.svg";

import buttomLine from "../../assets/bottom-line.png"

const Setting = () => {
    const navigate = useNavigate();

     const logoutHandler = () =>{
        navigate("/login")
     }


    return (
        <div className="container-fluid">
            <div className="row row-100vh">
                <div className="col-md-12 top mt-5 px-md-5">

                    <div className="d-flex clearfix">
                        <div className="float-start">
                            <Link to={"/dashboard"}><img src={arrowIcon} alt="image" className="img-fluid" /></Link>

                        </div>
                        <div className="float-end w-100">
                            <h4 className="text-color text-center">Settings</h4>
                        </div>
                    </div>

                </div>
                <div className="col-md-12 middle mt-4 px-md-5">

                    <h5 className="text-uppercase text-black">Accounts</h5>

                    <ul className="list-group">
                        <li className="list-group-item border-0 px-0 mb-3 h6 text-color fw-semibold d-flex justify-content-between align-items-center pointer_setting" onClick={()=>navigate("/edit-profile")}>
                            <span><img src={editProfile} alt="edit" className="img-fluid me-3 edit-logo" /> Edit Profile</span>
                            <span className="">
                                <img src={rightArrowIcon} alt="arrow" className="img-fluid " />
                            </span>
                        </li>

                        <li className="list-group-item border-0 px-0 mb-3 h6 text-color fw-semibold d-flex justify-content-between align-items-center pointer_setting" onClick={()=>navigate("/notifications")}>
                            <span><img src={notificationIcon} alt="notification" className="img-fluid me-3 edit-logo" /> Notifications</span>
                            <span className="">
                                <img src={rightArrowIcon} alt="arrow" className="img-fluid" />
                            </span>
                        </li>

                    </ul>

                    <h5 className="text-uppercase text-black">Support & About</h5>

                    <ul className="list-group">
                        <li className="list-group-item border-0 px-0 mb-3 h6 text-color fw-semibold d-flex justify-content-between align-items-center pointer_setting" onClick={()=>navigate("/about-us")}>
                            <span><img src={aboutIcon} alt="about" className="img-fluid me-3" /> About Us</span>
                            <span className="">
                                <img src={rightArrowIcon} alt="arrow" className="img-fluid" />
                            </span>
                        </li>

                        <li className="list-group-item border-0 px-0 mb-3 h6 text-color fw-semibold d-flex justify-content-between align-items-center pointer_setting" onClick={()=>navigate("/privacy-policy")}>
                            <span><img src={privacyICon} alt="privacy" className="img-fluid me-3 edit-logo" /> Privacy Policy</span>
                            <span className="">
                                <img src={rightArrowIcon} alt="arrow" className="img-fluid" />
                            </span>
                        </li>

                        <li className="list-group-item border-0 px-0 mb-3 h6 text-color fw-semibold d-flex justify-content-between align-items-center pointer_setting" onClick={()=>navigate("/help-and-support")}>
                            <span><img src={helpIcon} alt="help" className="img-fluid me-3 edit-logo" /> Help & Support</span>
                            <span className="">
                                <img src={rightArrowIcon} alt="arrow" className="img-fluid" />
                            </span>
                        </li>


                        <li className="list-group-item border-0 px-0 mb-3 h6 text-color fw-semibold d-flex justify-content-between align-items-center pointer_setting" onClick={logoutHandler}>
                            <span><img src={logoutIcon} alt="logout" className="img-fluid me-3 edit-logo" /> Logout</span>
                            <span className="">
                                <img src={rightArrowIcon} alt="arrow" className="img-fluid" />
                            </span>
                        </li>

                    </ul>


                </div>
                <div className="col-md-12 bottom">
                    <div className="text-center">
                        <img src={buttomLine} alt="line" className=" bottom-line img-fluid" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Setting