import React from "react";
import { useNavigate } from "react-router-dom";
import arrowIcon from "../../assets/arrow.png"
const BackButton = () => {
  const navigate = useNavigate();
  return (
    <a onClick={() => navigate(-1)}>
      <img src={arrowIcon} alt="image" className="img-fluid" />
    </a>
  );
};

export default BackButton;
