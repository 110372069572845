import { configureStore } from "@reduxjs/toolkit";
import { signUpApi } from "./Services/SignUpApi";
import { loginApi } from "./Services/LoginApi";
import signUpSlice from "./Slices/signUpSlice";
import { helpAndSupportApi } from "./Services/HelpAndSuppoerApi";
import homeSlice from "./Slices/home.slice";
import invoiceSlice from "./Slices/invoice.slice";
import reportSlice from "./Slices/report.slice";
import loaderMiddleware from "./middleware/loadermiddleware";
import loaderSlice from "./Slices/loader.slice";

export const store = configureStore({
  reducer: {
    [signUpApi.reducerPath]: signUpApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [helpAndSupportApi.reducerPath]: helpAndSupportApi.reducer,
    loader: loaderSlice,
    signUpSlice: signUpSlice,
    home: homeSlice,
    invoice: invoiceSlice,
    report: reportSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loaderMiddleware,
      signUpApi.middleware,
      loginApi.middleware,
      helpAndSupportApi.middleware
    ),
});
