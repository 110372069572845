import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

const getInvoices = createAsyncThunk("getInvoices", async ({ page, limit }) => {
  const { data } = await api.get(
    `/client/invoices?page=${page}&limit=${limit}`
  );
  return data;
});

const getInvoiceDetails = createAsyncThunk("getInvoiceDetails", async (id) => {
  const { data } = await api.get(`/client/invoice/${id}`);
  return data;
});

export { getInvoices, getInvoiceDetails };
