import { createSlice } from "@reduxjs/toolkit";
import { getClientReports, getReportDetails } from "../Services/report.service";

const initialState = {
  reports: null,
  reportDetails: null,
};

const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  extraReducers: ({ addCase }) => {
    addCase(getClientReports.fulfilled, (state, { payload }) => {
      if (payload.success) {
        state.reports = payload.data;
      }
    });
    addCase(getReportDetails.fulfilled, (state, { payload }) => {
      if (payload.success) {
        state.reportDetails = payload.data;
      }
    });
  },
});

export default reportSlice.reducer;