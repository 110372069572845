import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrowIcon from "../../../assets/arrow.png";
import emailIcon from "../../../assets/email.svg";
import buttonLineIcon from "../../../assets/bottom-line.png";
import { useHelpAndSupportMutation } from "../../../Services/HelpAndSuppoerApi";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { createSupport } from "../../../Services/settings.service";

const HelpAndSupport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addHelpAndSopport] = useHelpAndSupportMutation();
  const [inputField, setInputField] = useState({
    emailAddress: "",
    subject: "",
    description: "",
  });

  const inputChangeHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    addHelpAndSopport(inputField)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        console.log(res);
        if (res?.success) {
          navigate("/setting");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
      });
  };

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      subject: "",
      description: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values?.subject) {
        errors.subject = "Required*";
      }
      if (!values?.description) {
        errors.description = "Required*";
      }
      return errors;
    },
    onSubmit: (values) => {
      dispatch(createSupport(values)).then(() => {
        navigate(-1);
      });
      //   alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <div className="container-fluid">
      <div className="row row-100vh">
        <div className="col-md-12 top mt-5 px-md-5">
          <Link to={"/setting"}>
            <img src={arrowIcon} alt="image" className="img-fluid" />
          </Link>
          <h4 className="my-3 text-color">Help & Support</h4>
        </div>
        <div className="col-md-12 middle mt-4 px-md-5">
          <form onSubmit={handleSubmit}>
            <div className="position-relative">
              <label
                for="exampleInputEmail1"
                className="form-label text-color h6 fw-semibold"
              >
                Email Address
              </label>
              <input
                type="text"
                className="form-control rounded-pill py-3 mb-4"
                placeholder="Enter your Email Address"
                aria-label="Username"
                aria-describedby="basic-addon1"
                name="emailAddress"
                // value={inputField.emailAddress}
                value={localStorage.getItem("email")}
                // onChange={inputChangeHandler}
                disabled
              />
              <img
                src={emailIcon}
                alt="user"
                className="img-fluid position-absolute password"
              />
            </div>
            <div className="position-relative">
              <label
                for="exampleInputEmail1"
                className="form-label text-color h6 fw-semibold"
              >
                Subject{" "}
                <span className="h6">
                  (topic)
                  {errors?.subject && (
                    <span className="error">{" " + errors?.subject}</span>
                  )}
                </span>
              </label>
              <input
                type="text"
                className="subject form-control rounded-pill py-3 mb-4"
                placeholder="Enter your Subject"
                // aria-label="Username"
                // aria-describedby="basic-addon1"
                name="subject"
                value={values?.subject}
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                for="exampleInputEmail1"
                className="form-label text-color h6 fw-semibold"
              >
                Description
                {errors?.description && (
                  <span className="error">{" " + errors?.description}</span>
                )}
              </label>
              <textarea
                className="subject form-control"
                placeholder="Enter Description"
                id=""
                rows="7"
                name="description"
                value={values?.description}
                onChange={handleChange}
              ></textarea>
            </div>
            <button
              type="submit"
              className="btn btn-lg bg-button w-100 rounded-pill text-white fw-bolder py-3 mt-4"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttonLineIcon}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;
