import { useSelector } from "react-redux";
import { ClockLoader } from "react-spinners";

const override = {
  position: "absolute",
  display: "block",
  margin: "0 auto",
  zIndex: 9999,
};

const Loader = () => {
  const { loaderState } = useSelector((state) => state.loader);
  return (
    <>
      {loaderState && (
        <div className="loader">
          <ClockLoader color="#83c326 " loading={true} cssOverride={override} />
        </div>
      )}
    </>
  );
};

export default Loader;
