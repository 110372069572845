import React from "react";

const InvoiceSubDetails = ({ details }) => {
  return (
    <div>
      <div className="col-md-9">
        <div className="mb-3">
          <h5 className="text-black  mb-3 mt-3">Job No: #{details?.job_no}</h5>
          <h6 className="text-color fw-semibold">
            {details?.technician_id?.full_name}
          </h6>
        </div>
      </div>
      <h5 className="text-black  mb-3 mt-3">Items</h5>
      {details?.products?.map((product) => (
        <h6 className="text-color">{product?.product_id?.name}</h6>
      ))}
      <hr />
    </div>
  );
};

export default InvoiceSubDetails;
