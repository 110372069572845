import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

const getClientReports = createAsyncThunk("getClientReports", async () => {
  const { data } = await api.get("/report/client");
  return data;
});

const getReportDetails = createAsyncThunk("getReportDetails", async (id) => {
  const { data } = await api.get(`/report/${id}`);
  return data;
});

export { getClientReports, getReportDetails };
