import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

const getServices = createAsyncThunk(
  "getServices",
  async ({ page, limit, slug, filter, customRange, dateRange }) => {
    const { data } = await api.get(
      `/client/jobs/${slug}?page=${page}&limit=${limit}&filter=${filter}&customRange=${customRange}&dateRange=${dateRange}`
    );
    return data;
  }
);

const getServiceDetails = createAsyncThunk("getServiceDetails", async (id) => {
  const { data } = await api.get(`/client/job/${id}`);
  return data;
});

export { getServices, getServiceDetails };
