import { startLoading, stopLoading } from "../Slices/loader.slice";

const loaderMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type.endsWith("/pending")) {
      // Dispatch startLoading action before async action starts
      dispatch(startLoading());
    } else if (
      action.type.endsWith("/fulfilled") ||
      action.type.endsWith("/rejected")
    ) {
      // Dispatch stopLoading action after async action completes
      dispatch(stopLoading());
    }

    // Pass the action to the next middleware in the chain
    return next(action);
  };

export default loaderMiddleware;
