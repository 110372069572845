import moment from "moment";
import { useEffect, useState } from "react";
import invoiceIcon from "../../assets/invoice-icon.png";

const ReportTab = ({ report, reportDetailNavigate }) => {
  return (
    <div
      key={report._id}
      className="card mb-3 card-shadow"
      onClick={() => reportDetailNavigate(report?._id)}
    >
      <div className="card-body position-relative">
        <img
          src={invoiceIcon}
          className="img-fluid float-end position-absolute top-0 end-0  me-4 mt-3"
          alt="invoices-icon"
        />
        <p className="card-text text-grey mb-0">{report?.job_no}</p>
        <h6 className="text-black my-2">{report?.title}</h6>
        <div className="clearfix">
          <div className="float-start">
            <h6 className="mb-0  text-color fw-bolder">{report?.subtotal}</h6>
          </div>
          <div className="float-end">
            <p className="mb-0">
              <small>{moment(report?.createdAt).format("MMM DD YYYY")}</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTab;
