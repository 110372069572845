import React from "react";
import logo from "../assets/logo.png";

const Splash = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-center min-vh-100">
            <img src={logo} alt="logo" className="img-fluid splash-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Splash;
