import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const signUpApi = createApi({
  reducerPath: "signUpApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    RegisterPost: builder.mutation({
      query: (data) => ({
        url: "/client/register",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),

  }),
});

export const {
  useRegisterPostMutation
} = signUpApi;
