import { createSlice } from "@reduxjs/toolkit";
import { getInvoiceDetails, getInvoices } from "../Services/invoice.service";

const initialState = {
  invoices: null,
  invoiceDetails: null,
};

const invoiceSlice = createSlice({
  name: "invoiceSlice",
  initialState,
  extraReducers: ({ addCase }) => {
    addCase(getInvoices.fulfilled, (state, { payload }) => {
      if (payload?.success) {
        state.invoices = payload?.data.data;
      }
    });

    addCase(getInvoiceDetails.fulfilled, (state, { payload }) => {
      if (payload?.success) {
        state.invoiceDetails = payload?.data;
      }
    });
  },
});

export default invoiceSlice.reducer;
