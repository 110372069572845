import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";
import { toaster } from "../utils/toaster";

const createSupport = createAsyncThunk("createSupport", async (obj) => {
  const { data } = await api.post("/support", obj);
  toaster(data);
  return data;
});

export { createSupport };
