import React from "react";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/arrow.png";
import logoThumbnail from "../../assets/logo-thumbnail.svg";
import location from "../../assets/location.svg";
import buttomLine from "../../assets/bottom-line.png";

const Detail = () => {
  return (
    <div className="container-fluid">
      <div className="row row-100vh">
        <div className="col-md-12 top mt-5 px-md-5">
          <Link to={"/schedule"}>
            <img src={arrowIcon} alt="image" className="img-fluid" />
          </Link>
          <h4 className="my-3 text-color">Details</h4>
        </div>
        <div className="col-md-12 middle px-md-5">
          <div className="card mb-3 border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-1 col-2">
                  <img src={logoThumbnail} className="img-fluid " alt="image" />
                </div>
                <div className="col-md-8 col-10 ps-0">
                  <h5 className="card-title text-color mb-1">
                    Rodent Pest Control Service
                  </h5>
                  <p className="card-text">
                    Pest Control & Hygiene Services, Seychelles
                  </p>
                </div>
                <div className="col-md-3">
                  <div className="float-end">
                    <p className="mb-0">
                      <small>3 days ago</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mt-3 text-color">Main Duties</h5>
          <ul className="ps-4">
            <li>
              Inspect buildings and premises for signs of pests infestation
            </li>
            <li>
              Apply pesticides in and around buildings and other structures
            </li>
            <li>Operate equipment in a safe and responsible manner</li>
          </ul>
          <h5 className="mt-4 text-color">Location</h5>
          <h6 className="mb-0 ">
            <img src={location} className="me-1 img-fluid" alt="img-fluid" />
            Mahé Island
          </h6>
          <h5 className="mt-4 text-color">Service Type</h5>
          <h6 className="mb-0 ">Pest Control</h6>
          <h5 className="mt-4 text-color">Timings</h5>
          <h6 className="mb-0 ">03 Jan 2023, 11:20AM</h6>
        </div>
        <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttomLine}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
