import axios from "axios";
import env from "../constants/envVars";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: env.api_url + "/api",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("logintoken"),
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 401) {
    if (error.response.status === 403) {
      sessionStorage.clear();
      localStorage.clear();
      toast.error("Unauthorized");
      setTimeout(() => {
        window.location.replace("/");
      }, 1000);
    }
    return error.response;
  }
);

export default api;
