import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loaderState: false,
};

const loaderSlice = createSlice({
  name: "loaderSlice",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loaderState = true;
    },
    stopLoading: (state) => {
      state.loaderState = false;
    },
  },
});

export const { startLoading, stopLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
