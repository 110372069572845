import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../Services/invoice.service";
import moment from "moment";
import { getClientReports } from "../../Services/report.service";
import ReportTab from "./ReportTab";

const Reporting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reports } = useSelector((state) => state.report);

  const reportDetailNavigate = (id) => {
    navigate(`/report-detail/${id}`);
  };

  useEffect(() => {
    dispatch(getClientReports());
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Layout />
        <div className="col-md-12 px-104 mt-5">
          {reports?.map((report) => (
            <ReportTab
              report={report}
              reportDetailNavigate={reportDetailNavigate}
            />
          ))}
          {/* {handleFilter().map((invoice) => (
            <div
              key={invoice.id}
              className="card mb-3 card-shadow"
              onClick={() => invoiceDetailNavigate(invoice.id)}
            >
              <div className="card-body position-relative">
                <img
                  src={invoiceIcon}
                  className="img-fluid float-end position-absolute top-0 end-0 me-md-5 me-4"
                  alt="invoices-icon"
                />
                <p className="card-text text-grey mb-0">
                  {invoice.invoiceNumber}
                </p>
                <h6 className="text-black my-2">{invoice.customerName}</h6>
                <div className="clearfix">
                  <div className="float-start">
                    <h6 className="mb-0  text-color fw-bolder">
                      {invoice.amount}
                    </h6>
                  </div>
                  <div className="float-end">
                    <p className="mb-0">
                      <small>{invoice.date}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
        </div>
        {/* <div className="col-md-12 bottom">
          <div className="text-center">
            <img
              src={buttomLine}
              alt="line"
              className=" bottom-line img-fluid"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Reporting;
