import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logoThumbnail from "../../assets/logo-thumbnail.svg";
import location from "../../assets/location.svg";
import buttomLine from "../../assets/bottom-line.png";
import Layout from "../layout/Layout";
import { act } from "react-dom/test-utils";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../Services/dashboard.service";
import moment from "moment";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import dayjs from "dayjs";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const Schedule = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("pills-home");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [customRange, setCustomRange] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const [filter, setFilter] = useState("");

  const { services } = useSelector((state) => state.home);

  useEffect(() => {
    if (activeTab) {
      console.log(filter, dateRange);
      dispatch(
        getServices({
          page: 1,
          limit: 100,
          slug:
            activeTab === "pills-home"
              ? "upcoming"
              : activeTab === "pills-profile" && "completed",
          filter,
          customRange,
          dateRange,
        })
      );
    }
  }, [activeTab, dateRange, filter]);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const scheduleServiceNavigate = (id) => {
    navigate(`/dashboard-detail/${id}`);
  };

  const CustomDropdown = () => {
    //activeTab === "pills-home"
    const options = {
      "pills-home": ["Day", "Week", "Month"],
      "pills-profile": [
        "Last Week",
        "Last 30 Days",
        "Last 60 Days",
        "Last 90 Days",
      ],
    };

    const handleClick = (e) => {
      setCustomRange(false);
      setFilter(e);
    };

    return (
      <DropdownButton
        as={ButtonGroup}
        key={"primary"}
        id={`dropdown-variants-primary`}
        variant={"primary"}
        title={"Filter"}
      >
        {options[activeTab]?.map((option) => (
          <Dropdown.Item onClick={() => handleClick(option)} eventKey={option}>
            {option}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => setCustomRange(true)} eventKey="4">
          Custom Range
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const DateRangePicker = () => {
    const handleDateChange = (date, dateString) => setDateRange(dateString);
    const dateFormat = "DD/MM/YY";
    return (
      <div className="date-range-picker">
        <RangePicker
          // defaultValue={[dayjs(), dayjs()]}
          onChange={handleDateChange}
          format={dateFormat}
          value={
            dateRange
              ? [
                  dayjs(dateRange[0], dateFormat),
                  dayjs(dateRange[1], dateFormat),
                ]
              : [dayjs(), dayjs()]
          }
        />
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <Layout />
        <div className="col-md-9 top mt-5 my-4 px-104">
          <ul
            className="nav nav-pills mb-3 nav-justified"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item me-5" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "pills-home" ? "active" : ""
                }`}
                onClick={() => handleTabChange("pills-home")}
              >
                Scheduled Services
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "pills-profile" ? "active" : ""
                }`}
                onClick={() => handleTabChange("pills-profile")}
              >
                Completed Services
              </button>
            </li>
          </ul>
        </div>
        <div className="col-md-3 mt-5 my-4">
          <div className="d-flex align-items-center">
            <CustomDropdown />
            {customRange && <DateRangePicker />}
          </div>
          {/* <button onClick={() => setShow(true)} className="filter-btn">
            Filter
          </button> */}
        </div>
        <div className="col-md-12  px-104">
          <div className="tab-content" id="pills-tabContent">
            <div
              // className={`tab-pane fade ${
              //   activeTab === "pills-home" ? "show active" : ""
              // }`}
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex="0"
            >
              {services?.map((service, i) => (
                <div
                  className="card mb-3 card-shadow"
                  key={i}
                  onClick={() => scheduleServiceNavigate(service?._id)}
                >
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-md-1 col-2">
                        <img
                          src={logoThumbnail}
                          className="img-fluid"
                          alt="image"
                        />
                      </div>
                      <div className="col-md-11 col-10 ps-0">
                        <h5 className="card-title text-color mb-1">
                          {service?.title}
                        </h5>
                        <p className="card-text">{service?.description}</p>
                      </div>
                    </div>
                    <p className="card-text">
                      <small className="text-muted">
                        Inspect buildings and premises for signs of pests or
                        infestation.
                      </small>
                    </p>
                    <div className="clearfix">
                      <div className="float-start">
                        <h6 className="mb-0 text-color">
                          <img
                            src={location}
                            className="me-1 img-fluid"
                            alt="img-fluid"
                          />
                          {"Mahé Island"}
                        </h6>
                      </div>
                      <div className="float-end">
                        <p className="mb-0">
                          <small>
                            {moment(service?.start_date).format("MMM DD YYYY")}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* Add more scheduled service cards if needed */}
            </div>
          </div>
        </div>
        {/* <div class="col-md-12 bottom">
          <div class="text-center">
            <img src={buttomLine} alt="line" class=" bottom-line img-fluid" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Schedule;
