import React from 'react'
import { Link } from "react-router-dom"
import arrowIcon from "../../../assets/arrow.png";
import notification from "../../../assets/notification.png";
import buttonLineIcon from "../../../assets/bottom-line.png"

const Notification = () => {

    const items = [
        { id: 1, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { id: 2, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { id: 3, content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." }
    ];



    return (
        <div className="container-fluid">
            <div className="row row-100vh">
                <div className="col-md-12 top mt-5 px-md-5">
                    <Link to={"/setting"}><img src={arrowIcon} alt="image" className="img-fluid" /></Link>
                    <h4 className="my-3 text-color">Notification</h4>
                </div>
                {
                    items.length > 0 ? <div className="col-md-12 middle px-0 mx-0 ">
                        <ul className="  notification-child px-0">
                            {items.map(item => (
                                <li key={item.id} className='px-md-5'>{item.content}</li>
                            ))}
                        </ul>
                    </div> : <div className="col-md-12 middle mt-4 px-md-5">
                        <div className="d-flex align-items-center justify-content-center mt-5">
                            <img src={notification} alt="logo" className="img-fluid" />
                        </div>
                        <h4 className="fw-semibold text-color text-center mt-3">No Notification</h4>
                    </div>
                }

                <div className="col-md-12 bottom">
                    <div className="text-center">
                        <img src={buttonLineIcon} alt="line" className=" bottom-line img-fluid" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Notification